import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Zmigrod = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #0F0F0F, #1E1E1E, #2A2A2A, #1E1E1E, #0F0F0F )",
        icons: "#000",
        navClass: "zmigrod",
      }}
      seo={{
        title: "Zmigrod",
        headerTitle: "zmigrod",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/zmigrod_main_1.png"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/zmigrod/",
      }}
    >
      <PortfolioHeader name="zmigrod" height="276" />
      <section className="container-fluid zmigrod_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>New Żmigród</h1>
              <ul>
                <li>Logo</li>
                <li>Branding</li>
                <li>Projekty opakowań</li>
                <li>Materiały promocyjne</li>
                <li>Katalogi</li>
                <li>Strona internetowa</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Zakłady Miesne Nowy Żmigród są obecne na rynku od początku lat
                dziewięćdziesiątych. Od początku działalności firmy produkcja
                opiera się na tradycyjnych regionalnych przepisach
                przekazywanych z pokolenia na pokolenie, a także na wysokiej
                jakości mięsie z lokalnych gospodarstw. Produkty Nowego Żmigrodu
                skierowane są do klientów oczekujących najwyższej jakości i
                niezapomnianych wrażeń smakowych.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="container-fluid mh30_section_3">
        <div class="row">
          <div class="col-md-6 col_1">
            <img
              className="img-fluid"
              src={require("../../assets/img/portfolio/zmigrod_main_2.png")}
              alt=""
            />
            <div class="img_wrapper">
              <img
                className="img-fluid"
                src={require("../../assets/img/portfolio/zmigrod_main_3.png")}
                alt=""
              />
            </div>
          </div>
          <div class="col-md-4 offset-md-2 col_2">
            <h3>Logo</h3>
            <div class="row">
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Black CMYK</p>
                    <h5>
                      <span>0</span>
                      <span>0</span>
                      <span>0</span>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 100 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>Grey black CMYK</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 70 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 63 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 62 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 59 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div class="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>0</span>
                  <span>0</span>
                </h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>RGB:</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 50 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 50 : 0}
                      />
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 50 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
              <div class="col-sm-6">
                <p>HEX</p>
                <h5>000000</h5>
              </div>
              <TrackVisibility once offset={300} className="col-sm-6">
                {({ isVisible }) => (
                  <>
                    <p>HEX</p>
                    <h5>
                      <CountUp
                        start={0}
                        duration={3}
                        end={isVisible ? 323232 : 0}
                      />
                    </h5>
                  </>
                )}
              </TrackVisibility>
            </div>
          </div>
        </div>
      </section>
      <section className="zmigrod_section_4">
        <div className="d-flex align-items-center justify-content-between">
          <div className="img-1">
            <img
              src={require("../../assets/img/portfolio/zmigrod_main_44.png")}
              alt=""
            />
          </div>
          <div className="m-auto">
            <h4>Projekty opakowań i materiałów promocyjnych</h4>
            <p>
              Naszym zadaniem było przeprowadzenie całkowitego rebrandingu
              marki. Zaprojektowaliśmy nowe logo, a także wszystkie elementy
              identyfikacyjne, opakowania, etykiety, ulotki, plakaty -
              odpowiadaliśmy również za zaprojektowanie wyglądu salonów
              firmowych Nowy Żmigród. Kulminacją naszych działań było stworzenie
              nowej strony internetowej firmy.
            </p>
          </div>
          <div className="img-2">
            <img
              src={require("../../assets/img/portfolio/zmigrod_main_5.png")}
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="zmigrod_section_5">
        <h4>Strona internetowa</h4>
      </section>
      <section className="zmigrod_section_6">
        <div className="row">
          <div className="col-md-6 offset-md-6 text-right">
            <div className="scroll_wrapper">
              <span />
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/zmigord_entire_page.jpg")}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="zmigrod_section_7">
        <img
          src={require("../../assets/img/portfolio/zmigrod_main_7.png")}
          alt=""
        />
      </section>
      <section className="zmigrod_section_8">
        <img
          src={require("../../assets/img/portfolio/zmigrod_main_8.png")}
          alt=""
        />
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Zmigrod;
